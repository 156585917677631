import styled from "styled-components";

import { COLORS } from "../globals/colors";
import {
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_850,
  TABLET_800,
  TABLET_768,
  MOBILE_460,
} from "../globals/sizes";

export const HeroContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #ececec;
  position: relative;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
    background: #ffffff;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0;
  position: absolute;
  bottom: 35%;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
    bottom: 28%;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    bottom: 20%;
  }

  @media (max-width: ${TABLET_800}px) {
    bottom: 10%;
  }

  @media (max-width: ${TABLET_768}px) {
    position: unset;
  }
`;

export const HeroPictureMobile = styled.div`
  width: 100%;
  display: none;

  @media (max-width: ${TABLET_768}px) {
    display: contents;
  }
`;

export const HeroTextDiv = styled.div`
  width: 100%;
  margin-left: 60px;
  box-sizing: border-box;

  @media (max-width: ${TABLET_768}px) {
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const Title = styled.h1`
  width: 48%;
  color: ${COLORS.redTitle};
  text-align: left;
  font: normal normal 500 40px/47px Roboto;
  color: #e0281a;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    width: 44%;
  }

  @media (max-width: ${TABLET_800}px) {
    width: 60%;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    font: normal normal normal 44px/52px Roboto;
    align-items: center;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Paragraph = styled.p`
  width: 30%;
  margin-top: 20px;
  color: ${COLORS.grayText};
  font: normal normal normal 20px/28px Roboto;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 38%;
  }

  @media (max-width: ${DESKTOP_1024}px) {
    width: 45%;
  }

  @media (max-width: ${TABLET_850}px) {
    width: 60%;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    margin: 0;
    font: normal normal normal 22px/28px Roboto;
  }
`;

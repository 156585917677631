import React from "react";
import "swiper/swiper-bundle.css";

import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import MapServiceOne from "../../images/locations/map_one.png";
import MapServiceThree from "../../images/locations/map_three.png";
import MapServiceTwo from "../../images/locations/map_two.png";
import { PATHS } from "../../config/urls.jsx";

import {
  LastText,
  MapContainer,
  MapDescription,
  MapImageContainer,
  MapsContainer,
  MapsContainerMobile,
  ServiceAreaContainer,
  ServiceAreaTitle,
  ServiceAreaTitleContainer,
  SliderContainer,
} from "./serviceArea.styles.ts";

const ServiceArea = () => {
  const mapServicesInformation = [
    {
      data: (
        <MapContainer href={PATHS.LOCATIONS_SERVICE_MAP} target="_blank">
          <MapImageContainer>
            <img src={MapServiceOne} alt="Location Service One" />
          </MapImageContainer>
          <MapDescription>
            70 South Orange Ave, Suite 220
            <span>Livingston</span>
            <LastText>NJ 07039</LastText>
          </MapDescription>
        </MapContainer>
      ),
      key: PATHS.LOCATIONS_SERVICE_MAP,
    },
    {
      data: (
        <MapContainer href={PATHS.LOCATIONS_SERVICE_MAP_TWO} target="_blank">
          <MapImageContainer>
            <img src={MapServiceTwo} alt="Location Service Two" />
          </MapImageContainer>
          <MapDescription>
            139 Harristown Rd, Suite 101B
            <span>Glen Rock</span>
            <LastText>NJ 07452</LastText>
          </MapDescription>
        </MapContainer>
      ),
      key: PATHS.LOCATIONS_SERVICE_MAP_TWO,
    },
    {
      data: (
        <MapContainer href={PATHS.LOCATIONS_SERVICE_MAP_THREE} target="_blank">
          <MapImageContainer>
            <img src={MapServiceThree} alt="Location Service Three" />
          </MapImageContainer>
          <MapDescription>
            80 Cottontail Lane, Suite 430
            <span>Somerset</span>
            <LastText>NJ 08873</LastText>
          </MapDescription>
        </MapContainer>
      ),
      key: PATHS.LOCATIONS_SERVICE_MAP_THREE,
    },
  ];

  return (
    <ServiceAreaContainer>
      <ServiceAreaTitleContainer>
        <ServiceAreaTitle>OUR LOCATIONS</ServiceAreaTitle>
      </ServiceAreaTitleContainer>
      <MapsContainer>
        {mapServicesInformation.map((mapInformation) => (
          <div key={mapInformation.key}>{mapInformation.data}</div>
        ))}
      </MapsContainer>
      <MapsContainerMobile>
        <SliderContainer>
          <Swiper
            loop={true}
            autoplay={true}
            centeredSlides={true}
            speed={700}
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
              dynamicBullets: false,
              dynamicMainBullets: 2,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {mapServicesInformation.map((mapInformation) => (
              <SwiperSlide key={mapInformation.key}>
                {mapInformation.data}
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderContainer>
      </MapsContainerMobile>
    </ServiceAreaContainer>
  );
};

export default ServiceArea;
